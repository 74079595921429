<template lang="pug">
include ../pug/svg
div.search
	input(type="text" :placeholder="placeholder" :value="search" @input="updateSearch($event.target.value)")
	button.but.circ.plus(v-show="search" type="button" @click="updateSearch(null)")
		span Clear
		+svg(svg-filename="iconClose")
</template>

<script>
export default {
	name: 'Search',
	props: {
		search: String,
		placeholder: {
			type: String,
			default: 'Type to search...',
		},
	},
	emits: ['update:search'],
	methods: {
		updateSearch(search) {
			this.$emit('update:search', search);
		},
	},
}
</script>

<style lang="scss">
</style>
