<template lang="pug">
include ../pug/svg
div.filter(:class="{on:selected}")
	div
		label(v-if="label" :for="id") {{label}}
		span.input(:id="id" @click="toggleFilter(true)" :class="{space:clear!==false}") {{selected?.option}}
		button.but.clear(v-show="selected&&clear!==false" type="button" @click="applyFilter(null)")
			span Clear
			+svg(svg-filename="iconClose")
	nav.menu(ref="menu" :class="{active:active,reverse:reverse}" @mouseleave="toggleFilter(false)")
		div.bar
			+svg(svg-filename="iconMore" aria-hidden="true")
			span {{title||'Options'}}
			button.but(type="button" @click="toggleFilter(false)") Close
				+svg(svg-filename="iconClose")
		ul
			li(v-for="(option, index) in options")
				a(role="button" :class="{selected:selected?.value===option.value}" @click="applyFilter(option)") {{option.option}}
</template>

<script>
export default {
	name: 'Filter',
	props: ['options','selected','id','label','title','clear', 'setTint'],
	emits: ['update:selected'],
	data() {
		return {
			active: false,
			reverse: false,
		};
	},
	methods: {
		applyFilter(option) {
			this.toggleFilter(false);
			this.$emit('update:selected', option);
		},
		async toggleFilter(bool) {
			this.active = bool;
			if (this.setTint !== false) {
				this.$store.dispatch('gui/setTintState', bool);
			}
			
			this.reverse = false; // reset
			
			if (bool) {
				await this.$nextTick(); // ensure menu is open
				
				const menu = this.$refs.menu;
				const rect = menu.getBoundingClientRect();
				
				if (rect.bottom > window.innerHeight) {
					this.reverse = true;
				}
			}
		},
	},
}
</script>

<style lang="scss">
</style>
