<template lang="pug">
include ../pug/svg
div#ctrl
	div
		Search(v-model:search="search")
		Filter(v-if="!mediaType" id="typeFilter" label="Type" title="Media Types" :options="typeFilters" v-model:selected="typeFilter")
		//-Filter(id="groupFilter" label="Group" title="Media Groups" :options="groupFilters" v-model:selected="groupFilter")
		Filter(id="sortOption" label="Order By" title="Order By" :options="sortOptions" v-model:selected="sortOption" :clear="false")
		button.but.circ.plus.text(v-if="!modal" type="button" @click="addMedia")
			span Add {{terminology('media','media','plural')}}
			+svg(svg-filename="iconPlus" aria-hidden="true" alt="Add icon")
div#mediaGrid.wrapper(:class="{select:modal}")
	ul.c1.grid
		//-li.media(:data-id="media.id" v-for="(media, index) in filteredMedias" :key="media.id" :media="media" :index="index" @click="previewIndex=index" :class="media.mediaType, isSelected(media)")
		li.media(:data-id="media.id" v-for="(media, index) in filteredMedias" :key="media.id" :media="media" :index="index" @click="swapPreview(index)" :class="media.mediaType, isSelected(media)")
			picture(v-if="media.mediaType==='image'" :class="{active:previewIndex===index,unloaded:!media.loaded}")
				img(:src="media.signedUrl" @load="onLoad(media)")
			video(v-if="media.mediaType==='video'" preload="metadata")
				source(:src="media.signedUrl" :type="media.mimetype")
			audio(v-if="media.mediaType==='audio'" preload="metadata")
				source(:src="media.signedUrl" :type="media.mimetype")
			span.audio(v-if="media.mediaType==='audio'")
				+svg(svg-filename="iconSoundwave" aria-hidden="true" alt="Soundwave icon")
			button.but.select(v-if="modal" type="button" @click="toggleSelection(media)")
				span Select {{terminology('media','media','singular')}}
				//-+svg(svg-filename="iconCog" aria-hidden="true" alt="Edit icon")
			div.butdbl(v-else)
				button.but(type="button" @click="editMedia(media)")
					span Edit {{terminology('media','media','singular')}}
					+svg(svg-filename="iconEdit" aria-hidden="true" alt="Edit icon")
				button.but(type="button" @click="deleteMedia(media)")
					span Delete {{terminology('media','media','singular')}}
					+svg(svg-filename="iconBin" aria-hidden="true" alt="Edit icon")
	div.c2.sticky
		div.preview(v-if="previewMedia")
			div.head
				h6 {{previewMedia.mediaType}}
			figure
				picture(v-if="previewMedia.mediaType==='image'")
					img(:src="previewMedia.signedUrl")
				video(ref="videoPreview" v-if="previewMedia.mediaType==='video'" preload="metadata" :src="previewMedia.signedUrl" :type="previewMedia.mimetype")
					//-source(:src="previewMedia.signedUrl" :type="previewMedia.mimetype")
				audio(v-if="previewMedia.mediaType==='audio'" preload="metadata")
					source(:src="previewMedia.signedUrl" :type="previewMedia.mimetype")
				span.audio(v-if="previewMedia.mediaType==='audio'")
					+svg(svg-filename="iconSoundwave" aria-hidden="true" alt="Soundwave icon")
				figcaption 
					span {{previewMedia.filename}}
				div.butdbl
					button.but(type="button" @click="editMedia(previewMedia)")
						span Edit {{terminology('media','media','singular')}}
						+svg(svg-filename="iconEdit" aria-hidden="true" alt="Edit icon")
					button.but(type="button" @click="deleteMedia(previewMedia)")
						span Delete {{terminology('media','media','singular')}}
						+svg(svg-filename="iconBin" aria-hidden="true" alt="Edit icon")
			div.body
				table
					tr
						th Filesize
						th Uploaded
						th(v-if="previewMedia.mediaType==='image'") Dimensions (W/H)
						th(v-else) Duration
					tr
						td {{formatBytes(previewMedia.filesize, 1)}}
						td {{formatDate(previewMedia.createdAt)}}
						td(v-if="previewMedia.mediaType==='image'") {{previewMedia.width}}px / {{previewMedia.height}}px
						td(v-else) -
					//-tr
						th(colspan="3") Filename
					//-tr
						td(colspan="3") {{previewMedia.filename}}
					tr
						th(colspan="3") Name
					tr
						td(colspan="3") {{previewMedia.name||'-'}}
					tr(v-if="!modal && previewMedia.mediaType==='image'")
						th(colspan="3") Alt Text
					tr(v-if="!modal && previewMedia.mediaType==='image'")
						td(colspan="3") {{previewMedia.seoAlt||'-'}}
					tr(v-if="!modal && previewMedia.mediaType==='image'")
						th(colspan="3") Image Caption
					tr(v-if="!modal && previewMedia.mediaType==='image'")
						td(colspan="3") {{previewMedia.seoDescription||'-'}}
					tr(v-if="!modal")
						th(colspan="3") {{terminology('media','media','singular')}} Credit
					tr(v-if="!modal")
						td(colspan="3") {{previewMedia.credit||'-'}}
div.foot(v-if="modal")
	button.but.pri(@click="$emit('insertMedia')" :disabled="!selected.length") Insert
	button.but.sec(@click="$emit('closeModal')") Cancel
</template>

<script>
import Search from '../components/Search';
import Filter from '../components/Filter';
import dayjs from 'dayjs';

export default {
	name: 'MediaGrid',
	props: {
		modal: { // true = selection mode, false = management mode
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		mediaType: {
			type: String,
			validator(type) {
				return ['image', 'video', 'audio'].includes(type);
			},
			default: null,
		},
		selected: {
			type: Array,
			default: () => [],
		},
	},
//	emits: ['update:selected', 'addMedia', 'editMedia', 'deleteMedia'],
	emits: ['update:selected', 'addMedia', 'editMedia', 'deleteMedia','insertMedia','closeModal'],
	components: {
		Search,
		Filter,
	},
	data() {
		const sortOptions = [
			{
				value: 'newest',
				option: 'Most Recent',
			},
			{
				value: 'oldest',
				option: 'Least Recent',
			},
			{
				value: 'alpha',
				option: 'Name',
			},
		];

		const accessOptions = [
			{
				value: 'private',
				option: 'Private',
			},
			{
				value: 'restricted',
				option: 'Restricted',
			},
			{
				value: 'public',
				option: 'Public',
			},
		];

		return {
			previewIndex: 0,
			selectedMedia: [],
			search: null,
			sortOptions,
			sortOption: sortOptions[0],
			typeFilter: null,
			groupFilter: null,
			accessOptions,
			accessOption: null,
		};
	},
	async mounted() {
		await this.refreshMedia();

		if (this.mediaType !== null) {
			// restrict to supplied media type
			this.typeFilter = this.typeFilters.find(m => m.value === this.mediaType);
		}
	},
	computed: {
		medias() {
			// copy store to local (allows list sorting)
			return [...this.$store.state.media.medias];
		},
		filteredMedias() {
			// type filter
			let f1 = (this.typeFilter?.value) ? this.medias.filter(m => m.mediaType === this.typeFilter.value) : this.medias;

			// group filter
			let f2 = (this.groupFilter?.value) ? f1.filter(m => m.mediaGroup?.id === this.groupFilter.value) : f1;

			// sort order
			if (this.sortOption.value === 'newest') {
				f2.sort((a, b) => (b.createdAt > a.createdAt) ? 1 : -1);

			} else if (this.sortOption.value === 'oldest') {
				f2.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);

			} else if (this.sortOption.value === 'alpha') {
				f2.sort((a, b) => {
					const aFilename = a.filename.toLowerCase();
					const bFilename = b.filename.toLowerCase();
					
					if (aFilename < bFilename) {
						return -1;
					}
					if (aFilename > bFilename) {
						return 1;
					}
					
					// names must be equal
					return 0;
				});	
			}

			// serch term filter
			if (this.search) {
				const term = this.search.toLowerCase();

				return f2.filter(m => {
					// check term against filename, name, alt and description
					if (m.name && m.name.toLowerCase().includes(term)
						|| m.filename.toLowerCase().includes(term)
						|| (m.seoAlt && m.seoAlt.toLowerCase().includes(term))
						|| (m.seoDescription && m.seoDescription.toLowerCase().includes(term))) {
						return true;
					}

					return false;
				});

			} else {
				return f2;
			}
		},
		typeFilters() {
			// get all media types
			const mediaTypes = this.medias.map(m => {
				return m.mediaType;
			});

			// remove duplicates and map value/option pairs to media type
			return [...new Set(mediaTypes)].map(t => {
				return { value: t, option: t };
			});
		},
		groupFilters() {
			// map value/option pairs to group data
			return this.$store.state.media.mediaGroups.map(g => {
				return { value: g.id, option: g.name };
			});
		},
		previewMedia() {
			return this.filteredMedias[this.previewIndex];
		},
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		isSelected(media) {
			// check if selected
			return (this.selectedMedia.findIndex(m => m.id === media.id) > -1) ? 'selected' : null;
		},
		async refreshMedia() {
			// load media from db
			await this.$store.dispatch('media/dbMedias');
		},
		onLoad(media) {
			this.$store.dispatch('media/setMediaLoaded', {
				mediaId: media.id,
			});
		},
		toggleSelection(media) {
			// check if selected
			const index = this.selectedMedia.findIndex(m => m.id === media.id);

			if (index > -1) {
				// remove selection
				this.selectedMedia.splice(index, 1);

			} else {
				if (!this.multiple) {
					// remove current selection
					this.selectedMedia = [];
				}

				// add selection
				this.selectedMedia.push(media);
			}

			this.$emit('update:selected', this.selectedMedia);
		},
		addMedia() {
			this.$emit('addMedia');
		},
		editMedia(media) {
			this.$emit('editMedia', media);
		},
		deleteMedia(media) {
			this.$emit('deleteMedia', media);
		},
		formatBytes(bytes, decimals = 1) {
			if (bytes === 0) return '0 Bytes';

//			const k = 1024;
			const k = 1000;
			const dm = decimals < 0 ? 0 : decimals;
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

			const i = Math.floor(Math.log(bytes) / Math.log(k));

			return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
		},
		formatDate(datetime) {
			return dayjs(datetime).format('DD MMM YYYY');
		},
		swapPreview(index) {
			this.previewIndex = index;
			
			if (this.medias[index].mediaType === 'video') {
				
				this.$nextTick(() => {
					// reload video to update preview
					this.$refs.videoPreview.load();					
				});
			}
		},
	},
}
</script>

<style lang="scss">
#mediaGrid {
	display: flex;
	flex-grow: 1;
	width: 100%;
	.c1 {
		width: 100%;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		justify-content: flex-start;
		&:before { // empty placeholders
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: calc(100% + 70px);
			background: url(/img/li-outline-media2.svg);
			background-size: 180px 140px;
		}
		li {
			margin: 0 20px 20px 0;
			width: 160px;
			&:hover {
				.butdbl {
					opacity: 1;
				}
			}
			&.media {
				cursor: pointer;
			}
			&.selected {
				&:after {
					border-color: var(--pri-bg);
					background: rgba(#FFF, .7); // leave
				}
			}
		}
	}
	.c2 {
		flex-grow: 1;
		min-width: 320px;
		&.sticky {
			max-height: calc(100vh - 70px);
			overflow: scroll;
			&:empty {
				height: 100%;
				border: 1px solid #D8D8D8; // leave to match svg outlines
				border-radius: var(--box-rad);
			}
		}
	}
	&.select { // media selection (modal)
		.c2.sticky {
			top: 0;
		}
		button.select {
			z-index: 1;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;
		}
	}
	span.audio {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #000;
		svg {
			width: 90px;
			height: 66px;
			.fill {
				fill: #CCC;
			}
		}
	}
	picture {
		transition: opacity .3s ease-in-out;
		&.unloaded {
//			opacity: 0;
		}
	}
}
</style>
